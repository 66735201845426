// app/components/FAQContent.js
'use client';

import { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const CustomToggle = ({ children, eventKey, isActive, onClick }) => {
    return (
        <button
            className={`w-full text-left p-2 pl-4 bg-white border border-gray-200 rounded-t-md focus:outline-none ${
                isActive ? 'rounded-b-none' : ''
            }`}
            onClick={() => onClick(eventKey)}
        >
            <div className="flex justify-between items-center">
                <h5 className="text-md font-medium">{children}</h5>
                <ChevronDownIcon
                    className={`w-5 h-5 transition-transform ${isActive ? 'transform rotate-180' : ''}`}
                />
            </div>
        </button>
    );
};

const FAQContent = () => {
    const [activeKey, setActiveKey] = useState(null);

    const faqs = [
        {
            question: 'How does Buzzi AI work?',
            ans: `Buzzi AI operates by thoroughly analyzing your website or other business-related input data to create customized social media posts tailored to your brand. The tool generates post text content, visually appealing designs, and relevant hashtags that align with your brand's colors, fonts, and overall style. The AI ensures that each post resonates with your brand's identity and message. Additionally, users have the flexibility to edit the generated content to meet their specific needs, allowing for fine-tuning of text, design elements, and hashtags to ensure the posts perfectly align with their marketing strategies.`,
        },
        {
            question: `Can I customize the social media posts generated by Buzzi AI?`,
            ans:  `Yes, Buzzi AI provides extensive customization options for the generated posts. You can tailor the text for each social media platform to ensure it fits the tone and style appropriate for your audience. You can also modify the text styles, fonts, and text colors within the designs to better suit your brand's visual identity. Additionally, you have the option to add, remove, or modify hashtags to enhance reach and engagement based on current trends and specific campaign goals.`,
        },
        {
            question: `What social media platforms does Buzzi AI support?`,
            ans:  `Currently, Buzzi AI supports Facebook, Twitter, and Instagram. We are actively working on expanding our platform support based on user feedback and emerging social media trends. Our goal is to provide a comprehensive solution that covers all major social media platforms to maximize your brand's online presence.`,
        },
        {
            question: `Can Buzzi AI generate posts in different languages?`,
            ans:  `Yes, Buzzi AI is capable of generating social media posts in any language. This feature makes it an ideal tool for businesses operating in diverse regions and catering to multilingual audiences. By leveraging Buzzi AI, you can ensure that your social media content is accessible and engaging to a global audience.`,
        },
        {
            question: `How can I ensure my posts match my brand's style?`,
            ans:  `Buzzi AI allows you to meticulously customize your brand settings to ensure consistency across all your social media content. You can define your brand's colors, fonts, and other styling rules within the tool. Additionally, Buzzi AI supports branded headers and footers for each post or story, ensuring that every piece of content adheres to your brand guidelines and presents a cohesive visual identity.`,
        },
        {
            question: `I have my own product photos. Can I upload them to generate social media posts?`,
            ans:  `Yes, you can upload your product photos to Buzzi AI. The tool will incorporate these images into professionally designed social media posts, effectively showcasing your products. By using your own photos, you can ensure that the visual content is authentic and directly representative of your offerings.`,
        },
        {
            question: `Is Buzzi AI absolutely free?`,
            ans:  `Buzzi AI offers a free plan designed to help startups and small businesses get started with social media content creation. The free plan allows you to generate up to 5 post designs per month. For businesses with more extensive needs, we offer a range of paid plans that provide additional features and higher usage limits, ensuring that there is an option that fits different business requirements and budgets. Visit our pricing page for detailed information on the available plans and to choose the one that best suits your needs.`,
        },
    ];

    const renderFAQColumn = (startIndex, endIndex) => (
        <div className="w-full md:w-1/2 md:px-3">
            {faqs.slice(startIndex, endIndex).map((item, index) => {
                const actualIndex = startIndex + index;
                const isActive = activeKey === String(actualIndex);
                return (
                    <div key={actualIndex.toString()} className="mb-3">
                        <div className="border border-gray-200 rounded-md">
                            <CustomToggle
                                eventKey={String(actualIndex)}
                                isActive={isActive}
                                onClick={(key) => setActiveKey(key === activeKey ? null : key)}
                            >
                                {item.question}
                            </CustomToggle>
                            {isActive && (
                                <div className="p-4 bg-gray-50 text-gray-600 rounded-b-md">
                                    {item.ans}
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );

    return (
        <div className="container mb-28 pt-32">
            <h2 className='text-2xl mb-6'>Frequently asked questions (FAQ)</h2>
            <div id="faqContent" className="flex flex-col md:flex-row md:flex-wrap md:-mx-3">
                {renderFAQColumn(0, 4)}
                {renderFAQColumn(4, faqs.length)}
            </div>
        </div>
    );
};

export default FAQContent;