import React from 'react';
import Link from 'next/link';
import Slider from './slider';
import Image from 'next/image';

const Hero = () => {
  return (
    <section className="bg-gradient-to-t from-yellow-100/80 relative">
      <section className="relative pt-20 pb-20  md:pt-44 md:pb-40">
        <div className="container">
          <div className="grid md:grid-cols-7 grid-cols-1 gap-16 items-center">
            <div className="md:col-span-4 lg:col-span-3 order-1   text-center sm:text-start">
              <h1 className="text-3xl/tight sm:text-4xl/tight lg:text-5xl/tight font-semibold mb-7">
                Generate Social Media {' '}
                <span className="relative z-0 after:bg-yellow-200 after:-z-10 after:absolute after:h-6 after:w-full after:bottom-0 after:end-0">
                  Design
                </span>{' '} & Content
                With AI
              </h1>
              <p className="text-gray-500">
                The ultimate AI tool for generating and scheduling multilingual social media content, posts, stories,
                designs and hashtags
              </p>
              <div className="block flex flex-col sm:flex-row items-center justify-center lg:justify-start gap-4 mt-16">
                  <Link  href="https://app.buzzi.ai/auth#signup"
                    className="bg-primary text-white rounded-md hover:shadow-lg hover:shadow-primary/30 focus:shadow-none focus:outline focus:outline-primary/40 transition-all duration-500 py-3 px-6 text-lg min-w-[162px]"
                  >
                   Register Now
                 
                </Link>    

              {/*   <a href="https://www.producthunt.com/posts/buzzi-ai?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-buzzi&#0045;ai" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=476330&theme=light" alt="Buzzi.ai" style={{ width  : "250px", height: "54px"}} width="250" height="54" /></a>   */}
              </div>
            </div>
 
            <div className="md:col-span-3 lg:col-span-4 order-2  " data-aos="fade-left" data-aos-duration="1000">
            <div className="slider  relative   w-[400px] mx-auto">
              <div className="hidden sm:block">
                <div className="after:w-20 after:h-20 after:absolute after:-top-8 after:-end-8 after:-z-10 after:bg-[url('/images/pattern/dot5.svg')] hidden sm:block"></div>
                <div className="before:w-20 before:h-20 before:absolute before:-bottom-12 before:-start-12 before:-z-10 before:bg-[url('/images/pattern/dot2.svg')] hidden sm:block"></div>
              </div>  
              <Slider />
            </div>
            
            </div>
          </div>
        </div>
      </section>

      <div className="absolute bottom-0 inset-x-0 hidden sm:block">
        <img src="assets/images/shapes/white-wave.svg" alt="svg" className="w-full -scale-x-100 -scale-y-100" />
      </div>
    </section>
  );
};

export default Hero;