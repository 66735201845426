// File: app/(pages)/page.js
"use client";
import React, { useEffect } from 'react';
 
import Hero from './components/hero';
import Features from './components/features';
import Pricing from './components/pricing';

import Integrations from './components/integrations';
import Features2 from './components/features2';
import FAQContent from './components/faq';



const LandingPage = () => {
  
  return (<>
 
  <Hero></Hero>
  <a id='features'></a>
  <Features></Features>
  <Integrations></Integrations>
  <Features2></Features2>
  <a id='pricing'></a>
  <Pricing></Pricing>
  <a id='faq'></a>
<FAQContent></FAQContent>
  
   </>
  );
};

export default LandingPage;
