import React, { useState } from 'react';
import plans from '../../_models/plans.json';
import { XMarkIcon, CheckIcon } from '@heroicons/react/24/solid';



const Pricing = () => {
    const [useINR, setUseINR] = useState(false);
    

    const formatPrice = (plan) => {
        
            return `$${plan.price}/month`;
        
    };

    const formatFullPrice = (plan) => {
        if (plan.price === 0) return <>&nbsp;</>;
        
         return `$${plan.fullPrice}/month`;
        
    };

  return (
   <>
   <section className=" pb-16  xl:py-16">
    <div className="container" data-aos="fade-up" data-aos-duration="1500">
        <div className="text-center">
            <span className="text-sm font-medium py-1 px-3 rounded-full text-primary bg-primary/10">Pricing</span>
            <h1 className="text-3xl/tight font-semibold mt-3 mb-4">Pricing Plans </h1>
 
        </div>

        <div className="pt-16 overflow-x-auto">
            <div className="inline-block overflow-hidden">
                <table className="min-w-full leading-normal">
                    <thead>
                        <tr className="text-center divide-y">
                            <th className="px-5 py-4 xl:w-2/5 w-1/2 text-xs text-start border-b-2 border-t"></th>
                            {plans.plans.map((plan) => (
                                <th key={plan.name} className={`px-5 py-4 xl:w-[15%] w-1/4 ${plan.name !== 'Free' ? 'border-s' : ''}`}>
 
                                    <h1 className='font-normal text-2xl'  >{plan.name}</h1>
                                    <p className="text-red-500 text-sm line-through">{formatFullPrice(plan)}</p>
                                    <p className="text-gray-500 text-lg">{plan.price>0 && `$${plan.price}/month`} </p>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className='border-b'>
                        <tr className="text-center divide-y">
                            <td className="p-3 text-start border-b">
                                <p>Brands</p>
                            </td>
                            {plans.plans.map((plan) => (
                                <td key={plan.name} className="p-3 border-s">
                                    <span>{plan.brands}</span>
                                </td>
                            ))}
                        </tr>
                        <tr className="text-center divide-y">
                                    <td className="p-3 text-start border-b">
                                        <p className='font-bold'>Total Post Generation</p>
                                    </td>
                                    {plans.plans.map((plan) => (
                                        <td key={plan.name} className="p-3 border-s">
                                            {plan.premium === 0 ? (
                                                <span>5 Posts/month</span>
                                            ) : (
                                                <span className='font-bold'>{plan.premium + (plan.standard * 30)} Posts</span>
                                            )}
                                        </td>
                                    ))}
                                </tr>

                                <tr className="text-center divide-y">
                                    <td className="p-3 text-start border-b">
                                        <p ><span className='font-bold'>AI Image Generation</span><br></br>
                                      <span className='text-sm'>  subject to fair usage policy</span></p>
                                    </td>
                                    {plans.plans.map((plan) => (
                                        <td key={plan.name} className="p-3 border-s">
                                             {plan.premium === 0 ? (
                                                <span   >Unlimited</span>
                                            ) : (
                                                <span  className='font-bold'>Unlimited</span>
                                            )}
                                             
                                           
                                        </td>
                                    ))}
                                </tr>
                         
 
                        <tr className="text-center divide-y">
                            <td className="p-3 text-start border-b">
                                <p>Upload Product</p>
                            </td>
                            {plans.plans.map((plan) => (
                                <td key={plan.name} className="p-3 border-s">
                                    {plan.name === 'Free' ? (
                                        <span><XMarkIcon className="text-red-600 w-5 mx-auto block"></XMarkIcon></span>
                                    ) : (
                                        <span><CheckIcon className="text-green-600 w-5 mx-auto block"></CheckIcon></span>
                                    )}
                                </td>
                            ))}
                        </tr>
                       
                      
                        <tr className="text-center divide-y">
                            <td className="p-3 text-start border-b">
                                <p>No Watermark</p>
                            </td>
                            {plans.plans.map((plan) => (
                                <td key={plan.name} className="p-3 border-s">
                                    {plan.name === 'Free' ? (
                                      <span><XMarkIcon className="text-red-600 w-5 mx-auto block"></XMarkIcon></span> 
                                    ) : (
                                         <span><CheckIcon className="text-green-600 w-5 mx-auto block"></CheckIcon></span>
                                    )}
                                </td>
                            ))}
                        </tr>
                         
                        <tr className="divide-y">
                            <td  className="p-3 text-start border-b"> All Other features</td>
                            <td colSpan={4} className='p-3  border-s text-center    '><CheckIcon className="text-green-600 w-5 mx-auto mb-1 inline"></CheckIcon> Idea Generation, Branding (Your Business Logo, Color,  Fonts, Header & Footer Support), Multilingual Support, Optimized Tags, Schedule Post in all Social Media Channels...</td>
                        </tr>
                    </tbody>
                </table>
                
              
            </div>
        </div>
    </div>
   </section>
   </>
  );
};

export default Pricing;