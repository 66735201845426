import React from 'react';
import { Autoplay, EffectCreative } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from 'next/image';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-creative';

const Slider = () => {
  const posts = [
    {image:'/images/slides/1.png', name:"Reputed Firms", logo:"/images/slides/logo_rp.svg", time:"just now", message:"ReputedFirms simplifies business decisions with verified insights. Trust our research to choose the right partners based on customer experiences. <span>#BusinessGrowth #VerifiedReviews #SmartDecisions</span>"},
     
    {image:'/images/slides/2.png', name:"Burger Junction", logo:"/images/slides/logo_burger.png", time:"1 hour ago", message:"Burger enthusiasts, elevate your burger game with Burger Junction! Savor the taste of our house-ground hamburgers. <span>#BurgerLove #QualityIngredients #YummyBurgers</span>"},
    {image:'/images/slides/3.png', name:"Vegi Mart", logo:"/images/slides/logo_vegmart.png", time:"20 minutes ago", message:"Discover the Vegi Mart difference with our dependable quality and exceptional service. Fresh veggies delivered to your door! 🌿🥕  <span>#FreshVegetables #HomeDelivery #LocalProduce</span>"},
    {image:'/images/slides/4.png', name:"Modern Paints", logo:"/images/slides/logo_paint.png", time:"just now", message:"Transform your space effortlessly with Modern Paints' expert painting services. Enjoy a hassle-free experience and stunning results.  <span>  #HomeImprovement   #BeautifulSpaces</span>"},
    {image:'/images/slides/5.png', name:"Burger Junction", logo:"/images/slides/logo_burger.png", time:"3 hour ago", message:"Craving the perfect burger? 🍔 Our house-ground hamburgers are crafted fresh daily, packed with flavor!  <span>#BurgerLove #BurgerJunction #HouseGroundHamburger #FreshlyMade</span>"},
    {image:'/images/slides/6.png', name:"Modern Paints", logo:"/images/slides/logo_paint.png", time:"yesterday", message:"Global customers, experience the best in paint and decor with us. Elevate your space with Modern Paints' high-quality products & services.  <span>#ModernPaints #HomeImprovement #InteriorDesign </span>"},
    {image:'/images/slides/7.png', name:"Reputed Firms", logo:"/images/slides/logo_rp.svg", time:"10 minutes ago", message:"ReputedFirms helps you select the best vendors globally. Make informed decisions with verified reviews and ratings. <span>#VendorSelection   #VerifiedReviews #TrustedVendors</span>"}, 
    {image:'/images/slides/8.png', name:"Vegi Mart", logo:"/images/slides/logo_vegmart.png", time:"35 minutes ago", message:"Get your daily dose of fresh veggies with Vegi Mart’s home delivery service. Enjoy farm-fresh produce delivered straight to your door! <span>#FreshVeggies #HomeDelivery #FarmToTable</span>"},
 
  ];

  const swiperConfig = {
    direction: 'vertical',
    slidesPerView: 1,
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    effect: 'creative',
    creativeEffect: {
      prev: {
        shadow: true,
        translate: [0, '-120%', -500],
        rotate: [0, 0, -15],
      },
      next: {
        shadow: true,
        translate: [0, '120%', -500],
        rotate: [0, 0, 15],
      },
    },
    modules: [Autoplay, EffectCreative],
  };

  return (
    <div className="slider-container  h-[500px]  w-full ">
      <Swiper {...swiperConfig} className="    h-full w-full  ">
        {posts.map((post, index) => (
          <SwiperSlide key={index} className="swiper-slide-3d shadow-xl">
            <div className="slide-content">
              <div className='block bg-white border border-gray-200 p-2 rounded-lg    '>
              <div className="draft post-header flex items-center">
                <div className="w-8 h-8 rounded-full bg-gray-200 bg-contain bg-no-repeat bg-center" style={ { backgroundImage: `url('${post.logo}')` } }></div>
                <div className="ml-2">
                  <div className="font-regular text-sm text-gray-700  ">{post.name}</div>
                   <div className='text-xs text-gray-500'> {post.time}</div> 
                  
                </div>
              </div>
              <p className="postmessage text-xs my-2  text-gray-700" dangerouslySetInnerHTML={{ __html: post.message }}>
                   
                </p>
              <div>
                <img
                  src={post.image}
                  alt={`Slide ${index + 1}`}
                  className='post-image'
                  priority={index === 0}
               />
              </div>
             {/*   */}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <style jsx global>{`
        .slider-container {
          perspective: 1000px;
        }
        .swiper-slide-3d {
          transform: rotateX(45deg);
        }
        .slide-content {
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;
          box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        }
      `}</style>
    </div>
  );
};

export default Slider;