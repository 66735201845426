import React from 'react';

const Integrations = () => {
  return (
    <>
    <section className="bg-gray-100 xl:py-20 py-16">
<div className="container" data-aos="fade-up" data-aos-duration="1500">
    <div className="text-center">
        <span className="text-xs bg-primary/10 text-primary/90 rounded-full px-3 py-1">Scheduled Posts</span>
         
        <div className='flex  justify-center '>
       <h2 className="text-3xl/tight font-medium mt-3 mb-4  max-w-[660px]">
       AI optimizes your posting times for maximum reach  by analyzing past schedules, 
       target customers, and business parameters.
        </h2>
        </div>
    </div>

    
    <div className="pt-12 flex justify-center">
    <div className="grid xl:grid-cols-6 grid-cols-4 gap-7 items-stretch place-items-center auto-cols-fr">
        <div></div>
        <div className="h-full">
            <div className="bg-white flex flex-col items-center text-center rounded gap-5 p-5 h-full">
                <img src="/images/icons/social/facebook.svg" className="w-16"/> 
                <h3 className="mb-1 text-lg">Facebook</h3>
            </div>
        </div>
        <div className="h-full">
            <div className="bg-white flex flex-col items-center text-center rounded gap-5 p-5 h-full">
                <img src="/images/icons/social/instagram.svg" className="w-16"/> 
                <h3 className="mb-1 text-lg">Instagram</h3>
            </div>
        </div>
        <div className="h-full">
            <div className="bg-white flex flex-col items-center text-center rounded gap-5 p-5 h-full">
                <img src="/images/icons/social/x.svg" className="w-16"/> 
                <h3 className="mb-1 text-lg">X (Twitter)</h3>
            </div>
        </div>
        <div className="h-full">
            <div className="  flex items-center text-center rounded gap-5 p-5 h-full">
                 
                <p className="mb-1 text-md">...more</p>
            </div>
        </div>
        <div></div>
    </div>
</div>
   
</div>
</section>
    </>
  );

};

export default Integrations;

