 
import Features2 from './features2';
import Image from 'next/image';

const Features = () => {
  return (
    <section className="py-16 lg:py-32">
      <div className="py-10">
        <div className="container">
          <div className="text-center">
            <span className="text-sm font-medium py-1 px-3 rounded-full text-primary bg-primary/10">Features</span>
            <div className='flex  justify-center '>
<h1 className="text-3xl font-medium mt-3 mb-4 max-w-[660px] leading-[44px] text-gray-600">
    Your <span className=' text-primary'> Social Media Secret Weapon</span>: 
From Idea Generation, 
Post Design, 
Hashtag Creation 
to Post Scheduling -  All Powered by AI</h1>

            </div>
                      
          </div>

          <div className="grid lg:grid-cols-3 lg:gap-6 gap-10 mt-16">
            <div data-aos="fade-up" data-aos-duration="300">
              <div className="h-20 w-20   flex items-center justify-center">
                   <Image src="/images/icons/feature-content.svg" className="h-20 w-20 rounded-md" width="512" height="512"/> 
              </div>
            
              <h1 className="mb-3 mt-4 text-lg">Smart Content Generation</h1>
              <p className="text-gray-500">
              Buzzi analyzes your business to automatically generate engaging designs tailored to your business details and marketing strategy.
              </p>
            </div>

            <div data-aos="fade-up" data-aos-duration="600">
              <div className="h-20 w-20   flex items-center justify-center">
                   <Image src="/images/icons/feature-branding.svg" className="h-20 w-20 rounded-md" width="512" height="512"/> 
              </div>
              <h1 className="mb-3 mt-4 text-lg">Brand-Aligned Designs</h1>
              <p className="text-gray-500">
              Maintain brand consistency across all your posts. Customize colors, fonts, and design elements to perfectly reflect your brand identity.
              </p>
            </div>

            <div data-aos="fade-up" data-aos-duration="900">
            <div className="h-20 w-20   flex items-center justify-center">
                   <Image src="/images/icons/feature-schedule.svg" className="h-20 w-20 rounded-md" width="512" height="512"/> 
              </div>
              <h1 className="mb-3 mt-4 text-lg">Automated Post Scheduling</h1>
              <p className="text-gray-500">
              Our AI system schedules your posts for future publication across multiple social media platforms like Facebook Pages, X, Instagram, and more.
                </p>
            </div>
          </div>
        </div>
      </div>

      
      <div className="py-16 lg:py-28 overflow-x-hidden" data-aos="fade-up">
   <div className="container">
     
   <div className=" py-8">
                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 items-center">

                        <div className="relative">
                             <div className="hidden sm:block">
                                <div className="after:w-20 after:h-20 after:absolute after:-top-8 after:-start-8 2xl:after:end-0 after:bg-[url('/images/pattern/dot5.svg')]"></div>
                                <div className="before:w-20 before:h-20 before:absolute before:-bottom-8 before:-end-8 before:bg-[url('/images/pattern/dot2.svg')]"></div>
                            </div>  
                            <Image src="/images/branded-posts.png"  className='rounded-md' width="950" height="629" alt="Brand design" data-aos="fade-right" data-aos-duration="400" /> 
 
                        </div>

                        <div className="lg:ms-24">
                        <h1 className="text-3xl/tight font-medium mt-6 mb-4">Generate  Designs That Reflect Your Brand's Aesthetic</h1>
                            <p className="text-gray-500">Craft a visually stunning and cohesive brand presence with the ability to personalize every aspect of your design.</p>
                     
                        </div>

                    </div>
                </div> 
                </div>
                </div> 

      <div className="py-16 lg:py-28 overflow-x-hidden" data-aos="fade-up">
        <div className="container">
          <div className="relative">
            <div className="lg:absolute lg:max-w-md lg:translate-y-1/2 z-10 mb-14">
              <div className="bg-white shadow-lg border rounded-lg p-4 w-full">
                <div className="relative">
                  <div className="before:w-20 before:h-20 before:absolute before:-bottom-12 before:-start-12 before:-z-10 before:bg-[url('/images/pattern/dot2.svg')] hidden sm:block"></div>

                  <div className="h-20 w-20   flex items-center justify-center">
                   <Image src="/images/icons/feature-product.svg" className="h-20 w-20 rounded-md" width="512" height="512"/> 
              </div>

                  <h4 className="font-medium text-lg my-2">Product Photography, Perfected</h4>
                  <p className="text-gray-500">
                 Upload your photos and watch Buzzi transform them into eye-catching social media graphics with professional styling and backgrounds.
                  </p>
                  <p className="text-gray-500 mt-2">
                  No more expensive photoshoots or complicated editing software. Buzzi makes it easy to create scroll-stopping product visuals that drive sales.
                  </p>
                 {/*  <div className="mt-5 flex items-center">
                    <a href="#" className="text-primary text-sm">
                      Learn more <i className="fa-solid fa-arrow-right ms-2"></i>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="relative">
              <img src="/images/marketing.png" className="ms-auto rounded-md" alt="Marketing" />
              <div className="after:w-20 after:h-20 after:absolute after:-top-8 after:-end-8 after:-z-10 after:bg-[url('/images/pattern/dot5.svg')] hidden sm:block"></div>
            </div>
          </div>
        </div>
      </div>

     
    </section>
  );
};

export default Features;


 